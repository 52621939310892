h1, h2, h3, h4, h5, h6 {
    font-weight: 700!important;
}
.Footer__Copyright {
    width: 100%;
    text-align: center;
}
.NewsletterPopup {
    width: 100%;
    height: 100%;
    max-height: 500px;
    max-width: 700px;
    top: 10%;
    left: 0;
    bottom:0;
    right:0;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: center;
}
.NewsletterPopup__Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.NewsletterPopup__ContentWrapper, .NewsletterPopup__ImageWrapper {
  flex-basis: 50%;
  height: 100%;
}
.NewsletterPopup__ImageWrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ProductItem__ImageWrapper {
/*   background-color: #F7F7F9; */
}
.ProductMeta {
  padding-bottom: 0;
  border-bottom: none;
}
.tabbed-description {
    padding-top: 20px;
}
.tabbed-description .Product__Tabs {
    margin: 0;
}
.ProductForm ~ .ProductMeta__Description {
    border-top: 1px solid var(--border-color);
}
.ProducDetail__Title.Heading {
    margin-top: 0;
}
.Product__QuickNav a.Aside_Not_Link {
  position:relative;
  transform:rotateX(360deg);
}
.Rte .spr-summary-actions-newreview {
    color: var(--button-text-color) !important;
    text-decoration: none !important;
    border-color: var(--button-background);
}
.Rte .spr-summary-actions-newreview:hover {
    color: var(--button-background) !important;
}
.Rte img {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.product-features-image {
    margin: 10px 0;
}
.product-features-image img {
    display: inline-block;
    margin-left: 5px;
}

.ProductItem__Badges {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    text-align: right;
}
.ProductItem__Badge {
    display: inline-block;
    width: 25%;
}
.ProductItem__Badge img {
    width: 100%;
}
.Product__Gallery .ProductItem__Badges {
    right: 10px;
}
.Product__Gallery .ProductItem__Badge {
    width: 12%;
}
.wk-product-image {
    background-color: #f7f7f9;
}
.wk-product-info {
    text-align: center;
    font-size: calc(var(--base-text-font-size) - (var(--default-text-font-size) - 11px));
}
.wk-product-info, .wk-product-info a {
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    font-style: var(--heading-font-style);
    color: var(--heading-color) !important;
    transition: color .2s ease-in-out;
    display: block;
    margin-bottom: 4px;
    letter-spacing: .2em;
    text-transform: uppercase;
    text-decoration: none !important;
}
.wk-product-form__submit {
    color: var(--text-color-light) !important;
    border: 1px solid rgba(var(--text-color-light-rgb),.2) !important;
    text-transform: uppercase;
    position:relative;
}
.wk-product-form__submit:before {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--button-background);
    transform-origin: right center;
    transform: scaleX(0);
    z-index: -1;
    transition: transform .45s cubic-bezier(.785,.135,.15,.86);
}
.wk-product-form__submit:hover {
    color: var(--button-text-color) !important;
    border-color: var(--button-background) !important;
    background-color: var(--button-background) !important;
}
.wk-product-form__submit:hover:before {
    transform-origin: left center;
    transform: scale(1);
}
.wk-sharing h4.wk-title {
    font-size: 1.1em;
    text-align: center;
    margin: 10px 0 7px;
    padding: 0;
    text-transform: uppercase;
    border: none;
}
.wk-sharing__list {
    margin-left: 0 !important;
    padding: 0 !important;
}
.wk-share-button {
    font-size: 12px;
    text-decoration: none !important;
    color: #fff !important;
}
.wk-sharing {
    margin-bottom: 0px;
}
.DropdownMenu--restrict {
    max-height: 350px;
    overflow: auto;
}
.DropdownMenu--restrict {
    max-height: 55vh;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.DropdownMenu .Link {
    text-transform: uppercase;
}
.AspectRatio--square>img {
    -o-object-fit: cover;
    object-fit: cover;
}
.Image--contrast img:after {
    background-image: linear-gradient(to top,rgba(4,4,4,.25),rgba(54,54,54,.2));
}

@media screen and (min-width: 1140px) {
     .Brands__List {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }
}
@media screen and (min-width: 641px) {
  .wk-product-info {
      font-size: calc(var(--base-text-font-size) - (var(--default-text-font-size) - 12px));
  }
}

/* g */
.NewsletterPopup {
    padding: 0;
}
.NewsletterPopup__ContentWrapper {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 90px;
}
.NewsletterPopup__Container {
    height: 100%;
}
.CollectionList--spaced .CollectionItem {
/*     min-width: 20%;
    padding: 10px;
    margin: 0 0 15px; */
}
.CollectionList--spaced .CollectionItem .CollectionItem__Wrapper {
/*     height: auto; */
}
.CollectionList--spaced .CollectionItem .CollectionItem__Wrapper .CollectionItem__ImageWrapper {
/*     aspect-ratio: 1.1/1;
  transform: scale(1) !important;
      overflow: hidden; */
}
.CollectionList--spaced .CollectionItem .CollectionItem__Wrapper .CollectionItem__Content {
/*     position: static; */
}
.CollectionList--spaced .CollectionItem .CollectionItem__Wrapper .CollectionItem__Content .SectionHeader__ButtonWrapper {
/*     display: none; */
}
.CollectionList--spaced .CollectionItem .CollectionItem__Wrapper .CollectionItem__Content h2 {
/*     margin: 20px 0 0;
    color: #000 !important;
    text-align: center;
    font-size: 18px !important; */
}
.features--show-image-zooming .CollectionItem__ImageWrapper .CollectionItem__Image {
/*     transform: scale(1);
    transition: transform 8s cubic-bezier(.25,.46,.45,.94); */
}
.features--show-image-zooming .CollectionItem:hover .CollectionItem__ImageWrapper .CollectionItem__Image {
/*     transform: scale(1.2); */
}
.Brands__List .BrandsList__Item {
    display: flex;
    flex-wrap: wrap;
}
.Brands__List .BrandsList__Item .BrandsList__Item_Left {
    width: 240px;
    font-size: 22px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Brands__List .BrandsList__Item .BrandsList__Item_Right {
    width: calc(100% - 240px);
      display: flex;
    flex-wrap: wrap;
}
.Brands__List {
    padding: 60px 20px;    
    width:100%;
    margin:0 auto; 
}
.BrandsList__Item_Right .BrandsList__Item_List {
    width: 20%;
    padding: 10px;
}
.BrandsList__Item_Right .BrandsList__Item_List:hover a {
    color: #C17982;
}
.FeatureText--withImage .AspectRatio {
    max-height: 620px;
}
.FeatureText--withImage .AspectRatio img {
    object-fit: cover;
}
.BrandsList__Item_Right .BrandsList__Item_List .BrandsList__Item_Image a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.BrandsList__Item_Right .BrandsList__Item_List a,
.BrandsList__Item_Right .BrandsList__Item_List .BrandsList__Item_Image img {
    transition: transform .5s;
}
.BrandsList__Item_Right .BrandsList__Item_List:hover .BrandsList__Item_Image img {
    transform: scale(1.2);
}
.BrandsList__Item_Right .BrandsList__Item_List .BrandsList__Item_Image {
    position: relative;
    aspect-ratio: 1.1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F7F7F9;
    overflow: hidden;
}
.BrandsList__Item_Heading {
    text-align: center;
    padding: 15px;
}
.PageContent .Rte {
    text-align: center;
}
.Faq__Answer.Rte {
    text-align: left;
}
.contact__container {
    display: flex;
    flex-wrap: wrap;
    margin: 100px 0 0;
}
.contact__container .contact__left {
    width: 33%;
    text-align: left;
    font-size: 15px;
}
.contact__container .contact__right {
    width: 67%;
}
.contact__container .contact__right form#contact_form {
    margin: 0;
}
.contact-info--email, .contact-info--phone, .contact-info--address {
    margin: 0 0 7px;
}
.contact-info--email, .contact-info--phone {
    margin-top:15px;
}
.Collapsible__Content #shopify-product-reviews {
    margin-right: 0 !important;
}
.Timeline__Inner {
  max-height: initial;
}
.Timeline__Inner .SectionHeader__Description.Rte {
    position: relative;
    text-align: center;
}
.Footer__Content.Rte p a:not(.Button) {
    color: var(--footer-text-color);
}

@media screen and (max-width: 991px) {
  .contact__container .contact__left,
  .contact__container .contact__right {
    width: 100%;
    text-align: center;
  }
  .contact__container {
      margin: 50px 0 0;
  }
  .contact__container .contact__left {
      margin: 0 0 50px;
  }
}
@media screen and (max-width: 767px) {
  .Brands__List .BrandsList__Item .BrandsList__Item_Left {
      width: 50px;
      height: 50px;
      font-size: 15px;
  }
  .Brands__List .BrandsList__Item .BrandsList__Item_Right {
      width: calc(100% - 50px);
  }
  .BrandsList__Item_Right .BrandsList__Item_List {
      width: 33.3333%;
  }
}
@media screen and (max-width: 575px) {
  .BrandsList__Item_Right .BrandsList__Item_List {
      width: 50%;
  }
}
/* g */

svg#Layer_visa .cls-1 {
    fill: #142688;
}
svg#Layer_visa .cls-3 {
    fill: #fff;
    opacity: 1;
}
svg#Layer_tabby .cls-3 {
    fill: #292929;
    opacity: 1;
}
svg#Layer_mastercard .cls-2 {
    fill: #f55d33;
    opacity: 1;
}
svg#Layer_cod .cls-2, svg#Layer_tabby .cls-2, svg#Layer_mastercard .cls-3 {
    opacity: 1;
    fill: #fff;
}

input.Form__Input.cstm_newsletter_btn::placeholder {
    color: #000 !important;
}
.cstm_newsletter_btn {
    color: #000;
    background: #fff;
}
.cstm_newsletter_btn+button:hover {
    color: #fff !important;
}
input.Form__Input.cstm_newsletter_btn:focus, .cstm_newsletter_btn+button {
    border: 1px solid var(--border-color);
}

.Slideshow__Content .SectionHeader {
    margin-bottom:15px;
}  
.btn.klaviyo-bis-trigger { 
    display: block;    
    padding: 10px;
    color: #000;    
    text-align: center;
    letter-spacing: .2em;
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    font-style: var(--heading-font-style);
    text-transform:uppercase;
    font-size:12px;
    margin-top: 15px !important;
    position:relative;  
    border: 1px solid #c17982;
    transition: color .45s cubic-bezier(.785,.135,.15,.86),border .45s cubic-bezier(.785,.135,.15,.86);
    -webkit-transition: color .45s cubic-bezier(.785,.135,.15,.86),border .45s cubic-bezier(.785,.135,.15,.86);
}
.btn.klaviyo-bis-trigger:hover {
    color: var(--button-background);
    background-color: transparent;
}
.btn.klaviyo-bis-trigger:hover:before {
    transform-origin: right center;
    transform: scaleX(0);
}
.btn.klaviyo-bis-trigger:before {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: scale(1);
    transform-origin: left center;
    z-index: -1;
    transition: transform .45s cubic-bezier(.785,.135,.15,.86);
    -webkit-transition: transform .45s cubic-bezier(.785,.135,.15,.86);
    background-color: var(--button-background);
}
.klaviyo-bis-close .modal-title {
    text-transform: uppercase;
    line-height: 1.3;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 15px;
}
.klaviyo-bis-close .btn {
    font-family: Questrial, sans-serif;
}
.Footer__Newsletter .Form__Submit {
    width:100%;
    position:relative;    
}
.Footer__Newsletter .Form__Submit:before {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transform-origin: left center;
  z-index: -1;
  transition: transform .45s cubic-bezier(.785,.135,.15,.86);
  -webkit-transition: transform .45s cubic-bezier(.785,.135,.15,.86);
}
.Footer__Newsletter .Form__Submit:hover:before {
  transform-origin: right center;
  transform: scaleX(0);
  background-color: #c17982;  
}
.Footer__Newsletter .Form__Submit:hover {
  background-color: #ffffff !important;
  color:#c17982 !important;
}

body .Avada-Preview_Popup {
    width:100%;
    max-width:27em;
}
.Avada-Preview_Footer {
    display:none;
}
body .Avada-Preview_Popup .Avada-Preview_Body {
    height: 250px;
    overflow-y: hidden;
    background-color: #e8e8e8;
    border-radius: 10px;
    border: 5px solid #fff;
}
body .needsclick.kl-teaser-Rdy3DX {
    height:75px !important;
    width:75px !important;
}
button#Avada-FAQ_WidgetTrigger {
    width:50px;
    height:50px;
    position:relative;
}
button#Avada-FAQ_WidgetTrigger svg {
    width:50px;
    height:50px;
    border: 1px solid #ffffff;
    border-radius: 50%;
}
button#Avada-FAQ_WidgetTrigger:hover:before {
    opacity:1;    
}
button#Avada-FAQ_WidgetTrigger:before {
    position: absolute;
    content: "Click here for a free skincare consultation";
    top: 5px;
    right: 3px;
    padding: 4px 8px;
    display: block;
    width: 165px;
    line-height: 1.2;    
    color: #fff;
    font-size: 14px;
    text-align: center;
    background: #c17982;
    border-radius: 5px;
    border: 1px solid #fff;
    transform: translate(-60px);
    -webkit-transform: translate(-60px);
    opacity:0;
    transition:0.4s all;
    -webkit-transition:0.4s all;
    font-family:Questrial, sans-serif;
}
.lock button#Avada-FAQ_WidgetTrigger:before {
    left:125px;
}
.lock .Avada-Preview_Popup {
    z-index: 99999;
}
.lock .Avada-Preview_Button {
    z-index: 9999;
}
.lock .Avada-Preview_Button:before {
    left:0;
    transform: translate(60px);
    -webkit-transform: translate(60px);
}
.lock .Avada-Faqs_Button, 
.lock .needsclick {
    display:none;
}
body .Avada-Preview_Button svg {
    border: 3px solid #fff;
    border-radius: 50%;
    width:55px;
    height:55px;
}
.contact__container {
    width: 100%;
    max-width: 800px;
    margin: 50px auto 0;
}
.contact__container .contact__left {
    font-size: 14px;
    text-align:left;
    margin-bottom:30px; 
    font-family:Questrial, sans-serif;
}

.contact .section-header {
    width:100%;
    max-width:100%;
}
/*.contact__container .contact__left, 
.contact__container .contact__right {
    width:100%;  
}*/
.Avada-Preview_Body .Avada-Preview_Button-Contact {
    padding-top: 0px !important;
}
.Avada-Preview_Body .MuiTypography-body1 {
    display:none;
}
.Avada-Preview_Body .MuiTypography-h5 {
    margin-top:15px;  
}
.Avada-Preview_Body, 
.Avada-Preview_Body .MuiTypography-h6, 
.Avada-Preview_Body .MuiTypography-h5 {
  font-family:Questrial, sans-serif;
}
.Avada-Preview_Body .MuiTypography-h5 {
   letter-spacing:0.4px; 
}
.Avada-Preview_Body .MuiTypography-h6 {
    font-weight:normal;
    font-size:12px;
    text-transform:uppercase;
}
.lock .Avada-Preview_Button {
   bottom: 11em;
   display: block;
   left: 1.2em;
   width: 55px;
   height: 55px; 
}
.SidebarMenu.Drawer {
  z-index: 999999;
}
.page-contact .PageContent .Rte h4 {
    margin-top:15px !important;
}
#section-template--15209916039375__2f68df98-5fc3-4204-87b8-7e697c8505db .ImageHero__ContentOverlay {
    flex-basis: 825px;
    -webkit-flex-basis: 825px;
    text-shadow:none;
    padding: 35px 0px;
}
#section-template--15209916039375__2f68df98-5fc3-4204-87b8-7e697c8505db .SectionHeader__Description {
    max-width:100%;
}
#section-template--15209916039375__2f68df98-5fc3-4204-87b8-7e697c8505db .PlaceholderBackground {
    display:none;
}
.Header__MainNav ul li:nth-child(3) .DropdownMenu {
  overflow-y: scroll;
  -webkit-overflow-y: scroll;
  max-height: 360px;
}
.Header__Wrapper .Header__LocalizationForm {
   display:none; 
}

.Header .Header__FlexItem .doubly-wrapper .doubly-nice-select:after {
  border-color:#111111 !important;
}
.Header.Header--transparent .Header__FlexItem .doubly-wrapper .doubly-nice-select:after {
  border-color:#ffffff !important;
}
.Header .Header__FlexItem .doubly-wrapper .doubly-nice-select .current {
  color:#111111 !important;
}
.Header.Header--transparent .Header__FlexItem .doubly-wrapper .doubly-nice-select .current {
  color:#ffffff !important;
}
.Header .Header__FlexItem .doubly-wrapper {
  position: absolute !important;
  left: 25px !important;
}
.Header .Header__FlexItem .doubly-wrapper .doubly-nice-select.right .list {
  left: 0 !important;
  right: auto !important;
}
.Footer__Aside .doubly-wrapper {
    width:34%;
    margin-left:0px;
}
.Footer__Aside .doubly-nice-select.right {
    float:left;
}
.Footer__Aside .doubly-nice-select.right .list {
    left: 0;
    right: auto;
    bottom: 30px;
    top: auto;
}  
.Footer__Aside .doubly-wrapper .doubly-nice-select .current {
    color:#ffffff !important;
}
.Footer__Aside .doubly-wrapper .doubly-nice-select::after {
    border-color:#ffffff !important;
}


@media only screen and (min-width: 768px) and (max-width:1200px) {
  .Brands__List .BrandsList__Item .BrandsList__Item_Left {
    width: 15%;
    height:auto;
  }
  .Brands__List .BrandsList__Item .BrandsList__Item_Right {
    width: 85%;
  }  
}

@media only screen and (max-width:1139px) {
  .Header__Wrapper .Header__LocalizationForm {
      display:none !important;
      margin-left:30px;
  }
  body .Header .Header__FlexItem .doubly-wrapper {    
    left: 45px !important;
  }
}

@media only screen and (max-width:1007px) {
  #Avada-FAQ_Widget {
      position:relative;
      z-index:99999;
  }
  #shopify-section-header {
      z-index:99999;
  }
  .Timeline__ListItem {
      height: auto !important;
  }  
  .Timeline__ListItem .Timeline__Item {
      position: relative;
      flex-direction:column;
      -webkit-flex-direction:column;
  }
  .Timeline__Item .Timeline__ImageWrapper {
      position: absolute;
  }  
}

@media only screen and (max-width:999px) {
  [data-section-id="e12dc847-c9ff-4d02-9afc-78dc43768b23"] .ooo-instagram-feed__list {
    padding:0px !important;
  }
  .popover--bottom-start {
    z-index:99999;
  }
}

@media only screen and (min-width:992px) {
    .contact__container .contact__left {
        padding-right:35px;
    }
}

@media only screen and (min-width:768px) {
  footer .Footer__Aside {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }
}

@media only screen and (min-width:641px) {
  #section-template--15209916039375__8b3378ff-523a-4efa-b8cc-79f988eb355a .AspectRatio {
      padding-bottom: 100%;
  }
  footer .Footer__Inner {
      margin-left: -25px;
      margin-right: -25px;
  }
  .needsclick.kl-teaser-Rdy3DX {
      bottom:50px !important;
      margin-left: 5px !important;
  }   
  footer .Footer__PaymentList {  
      margin: 0px;
  }
  footer .Footer__PaymentList li:last-child {  
      margin-right: 0px;
  }
}

@media only screen and (max-width: 640px) {
  .Timeline__Item .Timeline__ImageWrapper:before {
    position:absolute;
    content:'';
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.6);    
  }
  .needsclick {
    margin: 0px 0px 15px 0px !important; 
  }
} 

@media only screen and (max-width:480px) {
  .Footer__Aside .doubly-nice-select.right .list {
    left: -70px !important;
  }
}


/*--- new css ---*/

body h2 {
  font-size:20px !important;
  font-weight:700 !important;
  letter-spacing: 1.5px !important;
}
body .h6 {
  font-size:12px !important;
  font-weight:700 !important;
  letter-spacing:1px;
}
.announcement-bar button {
   display:none; 
}
.announcement-bar .prose a {
   background:none; 
}
.blog-post-card__title {
  font-size:18px !important;
  font-weight:700 !important;
  letter-spacing:1px;
}
.button {
  font-size:12px !important;
  letter-spacing:2.2px;
}
.header .doubly-wrapper {
    margin:0 auto 0 0;
}
body .header .doubly-wrapper .list {
    top:30px;
    left: 0;
    right: auto;
}
.product .vendor {
    font-weight: 700;
    color: #111;
}
.product .product-title {
    font-size:18px;
    color: #000;
    letter-spacing: .1em;
    line-height:1.5;
}
.product .product-info__block-item {
    width:100%;
    display:block;
}
.rating-badge__stars .icon-star-rating path {
    fill:#f6a429;    
}
.rating-badge__stars .icon-star-rating-empty path {
    fill:#00000;
    fill-opacity: 1;
}
.product .price-list--product sale-price {
    font-size:16px;
    letter-spacing:1px;
}
.product-gallery .ProductItem__Badge {
    width: 12%;
}
.product-card .product-title {
    font-size:14px;
    letter-spacing:0.6px;
    font-weight: 700 !important;
    line-height:1.3;
}
.slideshow .prose .h6 {
    font-size:14px;
    font-weight:700;
}
.slideshow .prose .h1 {
    font-size:22px;
    font-weight:700 !important;
    letter-spacing:0.6px;
}
.slideshow .prose a.button {
    letter-spacing:1px;
}
footer .footer__block .h6 {
    font-size:12px !important;
    font-weight:700 !important;
    letter-spacing:1px;
}
footer .footer__block .h6 {
    color:#fff;
}
footer .footer__block p, 
footer .footer__block li a {
    color:rgba(255, 255, 255, 0.8);
}
footer .footer__block .social-media {
    margin-top:15px;
}
footer .footer {
    padding-bottom:45px;
}
.footer .form-control input {
    border-color: rgba(255, 255, 255, 0.8);
    color:#f1f1f1;
}
.footer .form .button {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.8);
    color: #fff;
}
.footer .form .button:hover {
    background-color: #fff!important;
    color: #c17982!important;
}
.footer .doubly-wrapper .list {
    bottom: 30px;
    top: auto;
    left: 0;
    right: auto;
}
.footer .doubly-nice-select .current {
   color:#fff;
}
.footer .doubly-nice-select:after {
   border-color:#fff;
}
li ul.header__dropdown-menu {
    max-height:380px !important;
}
.faq .accordion__toggle {
    font-size: 14px;
    letter-spacing: 0.2px;
    position:relative;
}
.faq .accordion__toggle .animated-plus {
   position: absolute;
   left: -24px;
  background: url('vector.svg');      
  width: 11px;  
  height: 11px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
}
.faq .accordion__toggle .animated-plus:before, 
.faq .accordion__toggle .animated-plus:after {
    background-color:unset;
}
.faq .accordion[aria-expanded=true] .accordion__toggle .animated-plus {
    transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
}
.faq .accordion__toggle .accordion__content {
    transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
}
.faq .faq__content .faq__category {
  font-size: 20px;
  font-weight: 700;
}
.faq .faq__content .accordion__toggle {
    padding-top:8px;
    padding-bottom:8px;
}
.faq .faq__content .accordion__toggle .text-with-icon {
  font-weight:500;
}
.faq .faq__content .accordion__content {
    margin-top:10px;
    margin-bottom:10px;
}
.faq .faq__content .accordion {
  border:none;
} 
.prose br {
  margin:0px !important;
}
.FlexboxIeFix.brand_top {
    position: relative;
    padding-top: calc(25% + 50px);
    height: 0;    
    display:block
}
.brand_top .PageHeader {
    position:absolute;
    top:-85px;
    bottom:0;
    width:100%;
}
.brand_top .PageHeader__ImageWrapper {
    position:absolute;
    top:0;
    bottom:0;
    width:100%;
    background-size:cover;
    background-position:center center;  
}
.brand_top .Image--contrast:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;    
    background-image: linear-gradient(to top,rgba(4,4,4,.65),rgba(54,54,54,.2));
}

.BrandsList__Item_Heading h2 {
    font-size: 14px!important;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.header__primary-nav ul li .h6 {
    font-weight: 400!important;
    letter-spacing: 1.2px;    
}
.footer__inner .footer__aside {
    margin-top: 80px;
}
.index .header:not(.is-solid) .doubly-nice-select .current {
    color:#fff;
}
.index .header:not(.is-solid) .doubly-nice-select.slim:after {
    border-color:#fff;
}
.header:hover .doubly-nice-select .current {
    color:#403F3F !important;
}
.header:hover .doubly-nice-select.slim:after {
    border-color:#403F3F !important;
}
header .header {
  box-shadow: 0 -1px #e1e1e1 inset;
}
.index header .header {
  box-shadow: none;
}
.index header .header.is-solid {
      box-shadow: 0 -1px #e1e1e1 inset;
}
.collection-banner h1 {
    font-size: 20px;
    font-weight: 500 !important;
    letter-spacing: 2px;
}
.collection-banner .content-over-media:before {
      background: linear-gradient(to top,rgba(4,4,4,.65),rgba(54,54,54,.2));
}
.header__primary-nav li .link-faded-reverse {
    transition: color none;
    -webkit-transition: color none;
}
.header__primary-nav-item .header__menu-disclosure > ul {opacity:1 !important;}
.header__primary-nav-item:hover .header__menu-disclosure > ul li {opacity:1 !important; transform: translateY(0px) !important;}

.collection-banner .content-over-media--sm {
    aspect-ratio: 3.80;
    --content-over-media-height: unset;
}
.product-info .shopify-payment-button button {
    letter-spacing: 2.2px;
}
.accordion.group .accordion__content {
    opacity:1 !important;  
} 
.product-info .product-info__block-group.accordion-group {
  margin-bottom:0px;
}
.product-info .product-info__block-group.reviews {
  margin-top:0px;
}
.product-info .product-info__block-group.reviews .accordion {
  border-top:0px;
}
.product-info .product-info__block-group.reviews .accordion__toggle {
  padding-block: 1.25rem;
}
body .badge--sold-out {  
  background:transparent;
  color: #111111;
  letter-spacing: 0.5px;  
}
.footer__aside .doubly-wrapper {
    margin-left: 0px;
}  
.footer__aside .doubly-nice-select.right {
    float:left;
}
.product-card .badge--on-sale, 
.product-card .badge--on-sale .money {
  font-size:0px;
}
.product-card .badge--on-sale {
  position:relative;
  background:transparent;
}
[lang="ar"] .product-card .badge--on-sale {
    width:100%;
}
.product-card .badge--on-sale:before {
    position: absolute;
    content: "ON SALE";
    font-size: 13px;
    color: #000;
    top: 0;
    left: 0;
    display: inline-block;
    width: 73px;
    letter-spacing: 1.25px;
    background: #ffffff;
    padding: 3px 5px 2px;
    line-height: 1.1;
}
.blog-post-card__info h2, 
.blog-post-card__info h2 a {
    line-height:1.3;
}
.prev-next-blog-posts {
    padding-bottom: 80px;
}  
.body_txt h4 {
    font-size: 16px;
    letter-spacing: 1.3px;
}
.shopify-section--main-page .container--xs {
  --container-max-width: 900px;
}
.product-card .product-card__info {
    line-height: 1.2;
}
/*.shopify-product-form .btn.klaviyo-bis-trigger { 
   --initial-gradient: linear-gradient(rgb(var(--button-background, var(--button-background-primary))), rgb(var(--button-background, var(--button-background-primary))));
   --hover-gradient: linear-gradient(transparent, transparent);
   position:relative; 
   background-image: var(--initial-gradient),var(--hover-gradient);
   transition: background-size .45s cubic-bezier(.785,.135,.15,.86),
               background-position .45s step-end,
               color .45s cubic-bezier(.785,.135,.15,.86),
               border .45s cubic-bezier(.785,.135,.15,.86);
   background: rgb(193, 121, 130);
   
   z-index:0; 
}
.shopify-product-form .btn.klaviyo-bis-trigger:before {
    position: absolute;	
    content: '';	
    top: 0;
    left:0;
    bottom:0;
	width: 0;
	height: 100%;
	transition: inherit;
} 
.shopify-product-form .btn.klaviyo-bis-trigger:hover:before {
    width:100%;
    background:#ffffff;
    transform-origin: unset;
    transform: none;
}
.shopify-product-form .btn.klaviyo-bis-trigger:hover {
    color:rgb(193, 121, 130);
}*/

.shopify-product-form .button:hover {
    color:#ffffff;
}

.shopify-product-form .btn.klaviyo-bis-trigger {
    display: block;
    text-decoration: none;
    letter-spacing: 2px;
    display:block;
    font-size:12px;
}
.product .product-features-image p {
    display:flex;
    display:-webkit-flex;
    flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    row-gap:20px;
    -webkit-row-gap:20px;
}
.product .product-features-image img {
    margin-block-start: unset !important;
    margin-block-end: unset !important;
}
wishlist-button-collection .wk-floating {
  z-index: 1;
}
.product-sticky-bar.is-visible .buy-buttons button:hover {
    color:#111111;
}
html {
    gap:0px !important;
}
body .Avada-Faqs_Popup.homePage .Avada-Faqs_Body {
    height: 260px;
}
body .Avada-Faqs_Popup.chatWithUsPage .Avada-Faqs_Body {
    height: 320px;
}
.Avada-Faqs_Popup .Avada-Faqs_Footer .AvadaFaqs-MuiTypography-h5 {
    opacity:0;
}

@media only screen and (max-width: 699px) {
  .product .Avada-Faqs_Button {
    right: auto;
    left: 1em;
    bottom: 10em;
  }  
  .product .Avada-Faqs_Button.opened {
      display:none;
  }
  .product .needsclick.kl-teaser-Rdy3DX {
      bottom:50px!important;
  }  
  .product.lock .Avada-Faqs_Button, 
  .product.lock .needsclick {
      display:none;
  }
  .product button#Avada-FAQ_WidgetTrigger:before {
      left:115px;
  }  
  .product .Avada-Faqs_Popup {
      bottom:2em;
  }
}

@media screen and (min-width: 700px) {
  .prev-next-blog-posts {     
      grid: auto / auto-flow minmax(min-content, 37rem);
  }
}
@media screen and (min-width: 1000px) {
  .faq {     
      justify-content: space-between;
      -webkit-justify-content: space-between;
      max-width: 990px;
      margin: 0 auto;
      width: 100%;
  }
}

@media only screen and (max-width:767px) {
  #sidebar-menu .header-sidebar__nested-linklist {
    opacity:1 !important;     
  }
  .collection .collection-banner .content-over-media--sm {
    aspect-ratio: unset;
  --content-over-media-height: unset;
  }  
  .collection .collection-banner .content-over-media--sm picture img {
    object-position: unset;
    object-fit: fill;
  }
}

/*
#linguise_floating_wrapper .linguise_switcher {
  position:fixed !important;  
  z-index:999 !important;
}

@media only screen and (min-width:700px) {
  #linguise_floating_wrapper .linguise_switcher {
    top:115px !important;
    right:45px !important;
  }
}

@media only screen and (max-width:699px) {
  #linguise_floating_wrapper .linguise_switcher {
    top:90px !important;
  }
}
*/
/*--------- RTL CSS --------*/

@media only screen and (max-width:999px) {
    [lang="ar"] .header {
      position:relative;
      padding-bottom:35px;
    }
    [lang="ar"] .doubly-wrapper {
      position: absolute;
      left: calc(50% - 50px);
      bottom: 2px;
    }
}

[lang="ar"] .footer__aside .doubly-wrapper {
    display:none;
}

[lang="ar"] .product-card__figure .badge-list {
    left:0;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}
.header .wk-icon svg path {
  stroke:#ffffff;  
}
.header:hover .wk-icon svg path, 
.header.is-solid .wk-icon svg path {
  stroke:#111111;  
}

@media only screen and (max-width:767px) {
  .lock .header-sidebar .header-sidebar__scroller {
    opacity:1;
  }
  .collection-banner .content-over-media .prose {
      padding:25px 0px;
  }
}

@media only screen and (max-width:699px) {
  header .header {
    height:85px;
    padding-top:40px;
  }
  .lang_curr_switch {  
    justify-content: space-between;
    width: 100%;
    padding:0px 20px;
  }
  .lang_curr_switch {
    top:4px !important;  
    left:0px;
    z-index:9;
  }
}

@media only screen and (min-width:700px) and (max-width:999px) {
  .lang_curr_switch {  
    justify-content: space-between;
    width: 100%;
    padding:0px 33px;
  }  
  .lang_curr_switch {
    top:5px !important;  
    left:0px;
    z-index:9;
  }
  header .header {
    height: 90px;
    padding-top: 40px;
  }
}
@media only screen and (min-width:1000px) {
  .lang_curr_switch {   
    left:48px;
  }
}

.lang_curr_switch {
  position: fixed;
  top:28px;
  display:flex;
}
.lang_curr_switch .sca-body-currency {
  position: unset !important;
  top:5px;
  margin-top:0px !important;
  margin-left:0px !important;
}
.lang_curr_switch .sca-currency-style {
  width:85px;
}

.lang_curr_switch .sca-currency-style .cs-select {
  display: block;
}
.lang_curr_switch .cs-placeholder {
  background-size: 1.8em auto !important;
  padding: 4px 0 3px 35px !important;
  border-radius: 3px !important;
  background-position: 7px 50% !important;
} 
.lang_curr_switch .lang_switch {
  margin-left:15px;
}
.lang_curr_switch .linguise_switcher {
   background:#ffffff;
   border-radius:3px;
}
.lang_curr_switch .linguise_switcher .linguise_current_lang, 
[lang="ar"] .lang_curr_switch .linguise_switcher .linguise_current_lang {  
  padding: 2px 6px !important;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  border:none !important;
}
#dropdown .linguise_switcher_dropdown li {
  border:none !important;
}
#dropdown .linguise_switcher_dropdown ul li {  
  padding: 5px;
}  
.lang_curr_switch .linguise_switcher .linguise_flags {
    width:21px !important;
    height:21px !important;
}
.lang_curr_switch .cs-placeholder, 
.lang_curr_switch .linguise_switcher .linguise_lang_name {
    color:#333333 !important;
    font-weight:400 !important;    
}
.lang_curr_switch .linguise_switcher .linguise_lang_name {    
    margin-left:4px !important;
    margin-right:0px;
}

[lang="ar"] .lang_curr_switch {
    right:35px;
}

[lang="ar"] .lang_curr_switch .lang_switch {
  margin-right:15px;
  margin-left:0px;
}
.lang_switch.chide {
  display:none;
}
